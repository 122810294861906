<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 text-right mb-3">
        <button class="btn btn-primary" @click="addQuestionnaire">
          <span>

          </span>
          Add Questionnaire
        </button>
        </div>
        <div class="col-12">
          <table class="table text-left">
            <thead>
              <th>Title</th>
              <th>Type</th>
              <th>Values</th>
              <th>Status</th>
              <th>Action</th>
            </thead>
            <tbody v-if="questionnaires.length>0">
              <tr v-for="(questionnaire, index) of questionnaires" :key="index">
                <td>{{questionnaire.title}}</td>
                <td>{{questionnaire.type}}</td>
                <td>
                  <div v-if="questionnaire.values.length>0">
                    <span
                      class="badge badge-secondary ml-2"
                      v-for="(v,i) of questionnaire.values"
                      :key="i"
                    >{{v.toUpperCase()}}</span>
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </td>
                <td>
                  <i v-if="questionnaire.is_active" class="fas fa-check text-success"></i>
                  <i v-else class="fas fa-ban text-danger"></i>
                </td>
                <td>
                  <span class="cursor-pointer" v-if="checkIsAccessible('questionnaire', 'edit')" @click="editQuestionnaire(questionnaire)">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="cursor-pointer ml-2" v-if="checkIsAccessible('questionnaire', 'delete')" @click="deleteQuestionnaire(questionnaire.id)">
                    <i class="fas fa-trash"></i>
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td></td>
                <td></td>
                <td>No Data Found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <create-and-update
      v-if="program.id"
      ref="questionnaire"
      :program="program"
      @refresh_list="getQuestionnaire"
    ></create-and-update>
  </div>
</template>
<script>
import QuestionnaireService from "@/core/services/level/program/questionnaire/QuestionnaireService";
import CreateAndUpdate from "./CreateAndUpdate";
import ProgramService from "@/core/services/level/program/ProgramService";

const programService = new ProgramService();
const questionnaireService = new QuestionnaireService();
export default {
  components: {
    CreateAndUpdate
  },
  props: ["program_id"],
  data() {
    return {
      questionnaires: [],
      program: {}
    };
  },
  mounted() {
    this.getQuestionnaire();
    this.getProgram();
  },
  computed: {},
  methods: {
    getQuestionnaire() {
      questionnaireService.getByProgram(this.program_id).then(response => {
        this.questionnaires = response.data;
      });
    },
    getProgram() {
      programService.show(this.program_id).then(response => {
        this.program = response.data.program;
      });
    },
    addQuestionnaire() {
      this.$refs["questionnaire"].showModal();
    },
    editQuestionnaire(questionnaire) {
      this.$refs["questionnaire"].showModal(questionnaire);
    },
    deleteQuestionnaire(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            questionnaireService.delete(id).then(response => {
              this.getQuestionnaire();
            });
          }
        }
      });
    }
  }
};
</script>
