<template>
  <v-app>
    <b-modal
      id="modal-scrollable"
      size="md"
      ref="my-modal"
      hide-footer
      :title="title"
    >
      <div class="row">
        <div class="col-12">
          <v-text-field outlined dense v-model="questionnaire.title" placeholder="Title"></v-text-field>
          <span class="text-danger" v-if="$v.questionnaire.title.$error">Title is required</span>
        </div>
        <div class="col-12">
          <v-select
            outlined
            dense
            :items="types"
            v-model="questionnaire.type"
            item-value="value"
            item-text="title"
            placeholder="type"
          ></v-select>
          <span class="text-danger" v-if="$v.questionnaire.type.$error">Type is required</span>
        </div>
        <div class="col-12" v-if="questionnaire.type && questionnaire.type != 'input' && questionnaire.type != 'textarea'">
          <v-combobox v-model="questionnaire.values" label="Values" multiple chips outlined dense></v-combobox>
          <span
            class="text-danger"
            v-if="questionnaire.type && $v.questionnaire.values.$error"
          >Values is required</span>
        </div>
        <div class="col-3 mt-4">
          <b-form-checkbox v-model="questionnaire.is_active" name="check-button" class switch>Status</b-form-checkbox>
        </div>
      </div>
      <!-- <pre>{{questionnaire}}</pre> -->
      <div class="row">
        <div class="col-6">
          <b-button
            size="sm"
            class="mt-3 text-primary"
            variant="default"
            block
            @click="hideModal"
          >Cancel</b-button>
        </div>
        <div class="col-6" v-if="checkIsAccessible('questionnaire', 'create')">
          <b-button size="sm" class="mt-2" variant="primary" block @click="saveOrUpdate">Save</b-button>
        </div>
      </div>
      <!-- <pre>{{questionnaire}}</pre> -->
    </b-modal>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import QuestionnaireService from "@/core/services/level/program/questionnaire/QuestionnaireService";
const questionnaireService = new QuestionnaireService();
export default {
  validations() {
    return {
      questionnaire: this.rules
    };
  },
  props: ["program"],
  data() {
    return {
      menu2: false,
      edit: false,
      types: [
        { title: "Input", value: "input" },
        { title: "Radio", value: "radio" },
        { title: "Select", value: "select" },
        { title: "Checkbox", value: "checkbox" },
        { title: "Textarea", value: "textarea" }
      ],
      questionnaire: {
        title: null,
        type: null,
        values: null,
        program_id: null,
        position: 1,
        is_active: true
      }
    };
  },
  computed: {
    rules() {
      let rule = {
        title: { required },
        type: { required }
      };
      if (this.questionnaire.type != "input" && this.questionnaire.type != "textarea") {
        rule.values = { required };
      }
      return rule;
    },title(){
      this.questionnaire.program_id = this.program.id
      return "Add/Edit Questionnaire "+ this.program.title; 
    } 
  },
  methods: {
    showModal(questionnaire = {}) {
      if (questionnaire.id) {
        this.edit = true;
        // this.questionnaire.is_active = questionnaire.is_active?true:false
        this.questionnaire = questionnaire;
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    saveOrUpdate() {
      this.$v.questionnaire.$touch();
      if (this.$v.questionnaire.$error) {
        setTimeout(() => {
          this.$v.questionnaire.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateQuestionnaire();
        } else {
          this.saveQuestionnaire();
        }
      }
    },
    updateQuestionnaire() {
      questionnaireService
        .update(this.questionnaire.id, this.questionnaire)
        .then(response => {
          this.$snotify.success("Information updated");
          this.resetForm();
        });
    },
    saveQuestionnaire() {
      questionnaireService.store(this.questionnaire).then(response => {
        this.$snotify.success("Information added");
        this.resetForm();
      });
    },
    resetForm() {
      this.questionnaire = {
        title: null,
        type: null,
        values: null,
        position: 1,
        is_active: true
      };
      this.edit = false
      this.$v.questionnaire.$reset();
      this.hideModal();
      this.$emit("refresh_list");
    }
  }
};
</script>
        
        <style>
</style>
      
